<template>
  <Toast />
  <ul class="clase-breadcrumb">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Ventas</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span class="titulo-new"> REPORTE GENERAL DE VENTAS</span>
    </div>
    <div class="col-12">
      <div class="p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-2">
            <span><strong>SUCURSAL: </strong></span>
            <Dropdown
              v-model="sucursalSelected"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>CÓDIGO VENTA: </strong></span>
            <InputNumber
              v-model="codigoVenta"
              placeholder="Código Venta"
              :inputClass="'text-right'"
              :min="0"
              :useGrouping="false"
              @keyup.enter="BuscarFiltro"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>CÓDIGO CAJA: </strong></span>
            <InputNumber
              v-model="codigoCaja"
              placeholder="Código Caja"
              :inputClass="'text-right'"
              :min="0"
              :useGrouping="false"
              @keyup.enter="BuscarFiltro"
            />
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>PACIENTE: </strong></span>
            <AutoComplete
              :dropdown="true"
              field="nombre"
              v-model="clienteSelected"
              :suggestions="clientesListadoFiltrado"
              @complete="buscarCliente($event)"
              placeholder="Buscar Cliente"
            />
          </div>
          <div class="field col-12 md:col-3">
            <span><strong>MÉDICO: </strong></span>
            <Dropdown
              v-model="medicoSelected"
              :options="medicos"
              optionLabel="nombre_completo"
              optionValue="id"
              :filter="true"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>USUARIO VENTAS: </strong></span>
            <Dropdown
              v-model="userSeleccionado"
              :options="users"
              optionLabel="name"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <!--  <div class="field col-12 md:col-2">
            <label for="nombre_paquete"><strong>PAQUETES: </strong></label>
            <Dropdown
              v-model="paqueteSelected"
              :options="paquetes"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div> -->
          <div class="field col-12 md:col-2">
            <span><strong>ESTADO VENTA: </strong></span>
            <Dropdown
              v-model="estadoSelected"
              :options="estados"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>FECHA INCIO: </strong></span>
            <Calendar
              v-model="fecha_inicio"
              :showIcon="true"
              dateFormat="dd/mm/yy"
              :showTime="true"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>FECHA FIN: </strong></span>
            <Calendar
              v-model="fecha_fin"
              :showIcon="true"
              dateFormat="dd/mm/yy"
              :showTime="true"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>M. DE PAGO: </strong></span>
            <Dropdown
              v-model="metodoPagoSelected"
              :options="metodo_pagos"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>ACCIONES: </strong></span>
            <Button
              label="BUSCAR"
              icon="pi pi-search"
              class="p-button-info"
              v-tooltip.top="'Buscar'"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
      <div class="mt-4 card style_card" v-if="ventas.length > 0">
        <div class="p-fluid formgrid grid">
          <table class="table table-bordered table-striped" style="width: 100%">
            <tr>
              <th class="text-center">
                TOTAL VENTAS:
                <span
                  class="p-badge p-badge-blue p-mr-2"
                  style="font-size: 1rem"
                  >{{
                    convertirNumeroGermanicFormat(
                      totalVenta + totalDescuento ?? 0
                    )
                  }}
                  Bs.</span
                >
              </th>
              <th class="text-center">
                TOTAL DESCUENTOS:
                <span
                  class="p-badge p-badge-danger p-mr-2"
                  style="font-size: 1rem"
                  >{{
                    convertirNumeroGermanicFormat(totalDescuento ?? 0)
                  }}
                  Bs.</span
                >
              </th>
              <th class="text-center">
                TOTAL GENERAL
                <span
                  class="p-badge p-badge-success p-mr-2"
                  style="font-size: 1rem"
                  >{{
                    convertirNumeroGermanicFormat(totalVenta ?? 0)
                  }}
                  Bs.</span
                >
              </th>
            </tr>
          </table>
        </div>
      </div>
      <div class="mt-4">
        <DataTable
          ref="reporte_ventas"
          key="id"
          :value="ventas"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm"
          :filters="buscar"
          responsive="true"
          stripedRows
          showGridlines
          v-model:expandedRows="expandedRows"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="buscar['global'].value"
                  placeholder="Buscar..."
                />
              </span>
              <span
                class="block mt-2 md:mt-0 p-input-icon-left"
                v-if="'R_Ventas de Ventas' in auth.user.permissions"
              >
                <Button
                  text
                  icon="pi pi-minus"
                  label="Contraer Todo"
                  @click="collapseAll"
                  class="mr-2"
                />
              </span>
              <span
                class="block mt-2 md:mt-0 p-input-icon-left"
                v-if="'R_Ventas de Ventas' in auth.user.permissions"
              >
                <Button
                  text
                  icon="pi pi-plus"
                  label="Expandir Todo"
                  @click="expandAll"
                  class="mr-2"
                />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <Button
                  v-if="'R_Ventas DescargarPDF' in auth.user.permissions"
                  label="PDF Imprimir"
                  icon="pi pi-file-pdf"
                  class="p-button-outlined p-button-danger mr-2"
                  v-tooltip.top="'Imprimir Reporte PDF'"
                  :loading="generandoPDF"
                  :disabled="generandoPDF"
                  @click="generarPDF"
                />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <Button
                  v-if="'R_Ventas DescargarEXCEL' in auth.user.permissions"
                  label="EXCEL Exportar"
                  icon="pi pi-file-excel"
                  class="p-button-outlined p-button-success"
                  v-tooltip.top="'Imprimir Reporte Excel'"
                  :loading="exportando"
                  :disabled="exportando"
                  @click="exportVentas"
                />
              </span>
            </div>
          </template>
          <template #loading>
            <div class="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          </template>
          <template #empty>
            <span class="p-invalid">
              Debe aplicar filtros para ver resultados en la tabla!</span
            >
          </template>
          <Column
            expander
            style="width: 1rem"
            v-if="'R_Ventas de Ventas' in auth.user.permissions"
          ></Column>
          <Column class="text-right" field="id" header="CÓD"> </Column>
          <!-- <Column field="sucursal" header="SUCURSAL">
            {{ data.sucursal }}
          </Column> -->
          <Column field="usuario" header="USUARIO" :sortable="true">
            {{ data.usuario }}
          </Column>

          <Column field="fecha_venta" header="F.VENTA" style="width: 70px">
            <template #body="{ data }">
              {{ formatDateTime(data.fecha_venta) }}
            </template>
          </Column>
          <Column
            field="nombre_cliente"
            header="PACIENTE"
            style="font-weight: bold"
          >
          </Column>
          <Column field="nit" header="CI/NIT"> </Column>
          <Column
            v-if="false"
            field="descuento"
            header="DESC."
            class="text-right"
            style="width: 20px"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.descuento) }}
            </template>
          </Column>
          <Column
            field="efectivo"
            header="EFECTIVO"
            class="text-right"
            style="width: 20px; font-weight: bold"
          >
            <template #body="{ data }">
              <span v-tooltip.top="'Efectivo'">
                {{ convertirNumeroGermanicFormat(data.efectivo) }}
              </span>
            </template>
          </Column>
          <Column
            field="transferencias"
            header="TRANSF."
            class="text-right"
            style="width: 20px; font-weight: bold"
          >
            <template #body="{ data }">
              <span v-tooltip.top="'Transferencias/Qr'">
                {{ convertirNumeroGermanicFormat(data.transferencias) }}
              </span>
            </template>
          </Column>
          <Column
            field="total_cancelar"
            header="T.VENTA"
            class="text-right"
            style="width: 20px; font-weight: bold"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.total_cancelar) }}
            </template>
          </Column>
          <Column field="facturado" header="ENVIADO" class="text-center">
            <template #body="{ data }">
              <span
                class="product-badge status"
                :class="{
                  'status-outofstock': data.facturado === 0,
                  'status-instock': data.facturado === 1,
                }"
              >
                {{ textoFacturado(data.facturado) }}
              </span>
            </template>
          </Column>
          <Column field="nombre_medico" header="MÉDICO">
            <template #body="{ data }">
              {{ data.nombre_medico ?? "SIN MÉDICO" }}
            </template>
          </Column>
          <Column field="estado" header="ESTADO">
            <template #body="{ data }">
              <span
                class="product-badge status"
                :class="{
                  'status-outofstock': data.estado === 0,
                  'status-instock': data.estado === 1,
                }"
              >
                {{ textoEstado(data.estado) }}
              </span>
            </template>
          </Column>
          <Column>
            <template #header>
              <i class="pi pi-cog text-xl ml-2" />
            </template>
            <template #body="slotProps">
              <Button
                class="p-button-rounded p-button-primary p-button-sm"
                v-tooltip.top="'Ver Acciones'"
                icon="pi pi-ellipsis-v"
                @click="$refs['menu' + slotProps.data.id].toggle($event)"
              />
              <Menu
                :ref="'menu' + slotProps.data.id"
                :popup="true"
                :model="acciones(slotProps.data)"
              >
              </Menu>
            </template>
          </Column>
          <template
            #expansion="slotProps"
            v-if="'R_Ventas de Ventas' in auth.user.permissions"
          >
            <div style="background-color: #ffffff">
              <h5>
                <span style="border-bottom: 1px solid #ccc">
                  DETALLE DE VENTA:
                  <strong
                    >{{ slotProps.data.id }} -
                    {{ slotProps.data.razon_social }}</strong
                  >
                </span>
              </h5>
              <DataTable
                :value="slotProps.data.productos"
                class="p-datatable-sm"
                responsiveLayout="scroll"
                stripedRows
                showGridlines
                selectionMode="single click"
                style="
                  border: 1px solid #000000;
                  border-radius: 2px;
                  padding: 2px;
                  box-shadow: 0px 0px 1px 0px #000000;
                  font-size: 12px;
                "
              >
                <Column
                  field="id"
                  header="COD_PROD"
                  class="text-right"
                ></Column>
                <Column
                  field="descripcion"
                  header="PRODUCTO/DESCRIPCIÓN"
                  style="width: 300px; font-weight: bold"
                ></Column>
                <Column header="CANTIDAD" class="text-right">
                  <template #body="slotProps">
                    {{ slotProps.data.cantidad }}
                  </template>
                </Column>
                <Column header="PRECIO" class="text-right">
                  <template #body="slotProps">
                    {{ slotProps.data.precio }}
                  </template>
                </Column>
                <Column header="SUBTOTAL" class="text-right">
                  <template #body="slotProps">
                    {{ slotProps.data.cantidad * slotProps.data.precio }}
                  </template>
                </Column>
                <Column header="DESCUENTO" class="text-right">
                  <template #body="slotProps">
                    {{ slotProps.data.descuento_neto }}
                  </template>
                </Column>
                <Column header="TOTAL" class="text-right">
                  <template #body="slotProps">
                    {{
                      slotProps.data.cantidad * slotProps.data.precio -
                      slotProps.data.descuento_neto
                    }}
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
        <VentaFacturar
          :show="ventafacturaDialog"
          :ventaFactura="ventaFactura"
          @closeModal="ocultarDialog"
          @actualizarListado="BuscarFiltro"
        >
        </VentaFacturar>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import VentaService from "@/service/VentaService";
import SucursalService from "@/service/SucursalService";
import UserService from "@/service/UserService";
import ClienteService from "@/service/ClienteService";
import { useAuth } from "@/stores";
import MedicoService from "@/service/MedicoService";
import PaqueteProductoService from "@/service/PaqueteProductoService";
import { sum } from "lodash";
import VentaFacturar from "@/module/ventas/VentaFacturar.vue";
import MetodoPagoService from "@/service/MetodoPagoService.js";

export default {
  components: { VentaFacturar },
  data() {
    return {
      fecha_inicio: null,
      fecha_fin: null,
      enviando: false,
      ventas: [],
      sucursalSelected: 0,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      userSeleccionado: 0,
      users: [{ id: 0, name: "TODOS" }],
      medicoSelected: 0,
      medicos: [{ id: 0, nombre_completo: "TODOS" }],
      paqueteSelected: 0,
      paquetes: [{ id: 0, nombre: "TODOS" }],
      generandoPDF: false,
      exportando: false,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      clienteSelected: null,
      clientesListadoFiltrado: [],
      estadoSelected: 2,
      estados: [
        { id: 2, nombre: "TODOS" },
        { id: 1, nombre: "VALIDA" },
        { id: 0, nombre: "ANULADA" },
      ],
      codigoVenta: null,
      codigoCaja: null,
      ventaFactura: {},
      ventafacturaDialog: false,
      expandedRows: [],
      metodo_pagos: [],
      metodoPagoSelected: 0,
    };
  },
  ventaService: null,
  sucursalService: null,
  userService: null,
  clienteService: null,
  auth: null,
  medicoService: null,
  paqueteProductoService: null,
  metodoPagoService: null,

  computed: {
    totalVenta() {
      return sum(
        this.ventas.map((venta) => {
          if (venta.estado == 1) {
            return parseFloat(venta.total_cancelar);
          } else {
            return 0;
          }
        })
      );
    },
    totalDescuento() {
      return sum(
        this.ventas.map((venta) => {
          if (venta.estado == 1) {
            return parseFloat(venta.descuento);
          } else {
            return 0;
          }
        })
      );
    },
  },
  created() {
    this.ventaService = new VentaService();
    this.sucursalService = new SucursalService();
    this.userService = new UserService();
    this.clienteService = new ClienteService();
    this.auth = useAuth();
    this.medicoService = new MedicoService();
    this.paqueteProductoService = new PaqueteProductoService();
    this.metodoPagoService = new MetodoPagoService();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarUsuarios();
    this.cargarMedicos();
    this.getMetodoPagos();
    /* this.cargarPaqueteProductos(); */
  },
  methods: {
    getMetodoPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos || [];
        this.metodo_pagos.unshift({ id: 0, nombre: "TODOS" });
      });
    },
    formatDateTime(dateString) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      return new Date(dateString).toLocaleString(undefined, options);
    },
    acciones(data) {
      return [
        {
          label: "Ver Detalle",
          icon: "pi pi-fw pi-eye",
          disabled:
            "Venta Ver Detalle" in this.auth.user.permissions ? false : true,
          to: `/ventas/${data.id}/detalle`,
        },
        {
          label: "Imprimir",
          icon: "pi pi-print",
          command: () => {
            this.imprimirVenta(data.id);
          },
        },
        {
          visible:
            "Venta Facturar" in this.auth.user.permissions ? true : false,
          label: "Crear Factura",
          disabled: data.facturado == 1 || data.estado == 0 ? true : false,
          icon: "pi pi-fw pi-file",
          // LLAMO A MI COMPONENTE DE VENTAFACTURAR
          command: () => this.ventaFacturar(data.id),
        },
      ];
    },
    ventaFacturar(id) {
      // paso todos los datos de la venta a mi componente de ventaFacturar
      this.ventaFactura = this.ventas.find((venta) => venta.id == id);
      this.ventafacturaDialog = true;
    },
    imprimirVenta(id) {
      this.ventaService
        .imprimirVenta(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    ocultarDialog() {
      this.ventafacturaDialog = false;
    },
    textoEstado(estado) {
      if (estado == 0) {
        return "ANULADA";
      } else if (estado == 1) {
        return "VALIDA";
      } else {
        return "PENDIENTE";
      }
    },
    buscarCliente(event) {
      setTimeout(() => {
        this.clienteService
          .buscarXNombre({ texto: event.query })
          .then((response) => {
            this.clientesListadoFiltrado = [...response.clientes];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    exportVentas() {
      if (this.ventas.length > 0) {
        let datos = {
          sucursal_id: this.sucursalSelected,
          user_id: this.userSeleccionado,
          fecha_inicio: this.fecha_inicio,
          fecha_fin: this.fecha_fin,
          cliente_id: this.clienteSelected,
          estado: this.estadoSelected,
          codigoVenta: this.codigoVenta,
          medico_id: this.medicoSelected,
          nombre_paquete: this.paqueteSelected,
          codigoCaja: this.codigoCaja,
        };
        this.ventaService.exportarVentas(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Exportación exitosa",
            detail: "Se ha exportado correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    generarPDF() {
      if (this.ventas.length > 0) {
        this.generandoPDF = true;
        let datos = {
          sucursal: this.sucursalSelected,
          user_id: this.userSeleccionado,
          fecha_inicio: this.fecha_inicio,
          fecha_fin: this.fecha_fin,
          cliente_id: this.clienteSelected ? this.clienteSelected.id : null,
          estado: this.estadoSelected,
          codigoVenta: this.codigoVenta,
          medico_id: this.medicoSelected,
          nombre_paquete: this.paqueteSelected,
          codigoCaja: this.codigoCaja,
        };
        this.ventaService.imprimirPDF(datos).then(() => {
          this.generandoPDF = false;
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: "Se genero el PDF correctamente",
            life: 3000,
          });
        });
      } else
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
    },
    cargarUsuarios() {
      this.userService.getUsersAll().then((data) => {
        this.users = data || [];
        this.users.unshift({ id: 0, name: "TODOS" });
      });
    },
    cargarMedicos() {
      this.medicoService.getMedicosAll().then((data) => {
        this.medicos = data.medicos || [];
        this.medicos.unshift({ id: 0, nombre_completo: "TODOS" });
      });
    },
    cargarPaqueteProductos() {
      this.paqueteProductoService
        .getPaqueteProductoAll()
        .then((data) => {
          if (data.length > 0) {
            data.paqueteproductos.forEach((element) => {
              this.paquetes.push(element) || [];
            });
          } else {
            this.$toast.add({
              severity: "warn",
              summary: "Advertencia",
              detail: "No hay paquetes registrados",
              life: 3000,
            });
          }
        })
        .catch((error) => console.log(error));
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((data) => {
          data.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    BuscarFiltro() {
      this.ventas = [];
      this.enviando = true;
      this.cargarReporteVentas();
    },
    cargarReporteVentas() {
      let datos = {
        sucursal: this.sucursalSelected,
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
        user_id: this.userSeleccionado,
        cliente_id: this.clienteSelected,
        estado: this.estadoSelected,
        codigoVenta: this.codigoVenta,
        medico_id: this.medicoSelected,
        nombre_paquete: this.paqueteSelected,
        codigoCaja: this.codigoCaja,
        metodo_pago: this.metodoPagoSelected ?? 0,
      };
      this.ventaService
        .filtrarVentas(datos)
        .then((data) => {
          this.ventas = data.ventas;
          this.productos = data.productos ?? [];
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE").format(numero);
    },
    textoFacturado(facturado) {
      if (facturado == 0) {
        return "NO";
      } else {
        return "SI";
      }
    },
    expandAll() {
      this.expandedRows = this.ventas.filter((p) => p.id);
    },
    collapseAll() {
      this.expandedRows = null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}

.p-fondo {
  background-color: #7bffae;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 5px;
  border: 1px solid #002236;
}
.style_card {
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
  padding: 3px;
}
.titulo-new {
  border-bottom: 1px solid #002236;
  font-size: 1.5rem;
  color: #002236;
  font-weight: bold;
}
.clase-breadcrumb {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
}
</style>
